import React from 'react'
import { Link } from 'gatsby'

const VerifyForm = ({submit}) => {
  return (
    <div className="form-wrapper key-verify-form">
      <form method="post" action="#">
        <input type="text" placeholder="E-mail address *" name="Email" id="Email" required />
        <input type="text" placeholder="Key *" name="GetKey" id="GetKey" required />
        <div className="submit-wrap">
          <Link to="/KeyVerification"><button type="submit">{submit}</button></Link>
        </div>
      </form>
    </div>

  )
}

export default VerifyForm